/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    var HELPER = HELPER || (function () {
        return {
            validateEmail: function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            ismobile: function () {

                var isMobile = false; //initiate as false
                // device detection
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) isMobile = true;
                return isMobile;


            },
            popuphandler: function () {
                if(HELPER.ismobile()){
                    return;
                }
                $(document).scroll(function() {
                    if(!$('#attentionPopup').hasClass('open-by-scroll')) {
                        var y = $(this).scrollTop();
                        if (y > 300 || y + $(window).height() == $(document).height()) {
                            $('#attentionPopup').removeClass('attention-closed');
                            $('#attentionPopup').addClass('attention-open');
                            $('#attentionPopup').addClass('open-by-scroll');
                        }
                    }
                });
                $('.attention-wrapper > .attention-close').on('click', function () {
                    $('#attentionPopup').addClass('attention-closed');
                    $('#attentionPopup').removeClass('attention-open');
                    setTimeout(function() {
                        $('#attentionPopup').remove();
                    }, 800);

                });

            },
            bookazineHandle: function () {
                //11410
                document.addEventListener('wpcf7mailsent', function (e) {
                   if( e.detail.contactFormId == ISTEC.bookazineID){
                      window.location.href = ISTEC.bookazineURL;
                      setTimeout(function () {
                          location.reload();
                      }, 3000)
                   }
                });
            }
        }
    }());

    $.fn.newsletterSubscribe = function () {
        var $form = $(this);
        // console.log($form);
        $form.find('#newsletterName').on('keyup', function () {
            if (HELPER.validateEmail($(this).val())) {
                $form.find('.notvalid').slideUp();

            }
            ;

        });


        $form.on('submit', function (event) {

            // grecaptcha.getResponse(recaptcha);
            event.preventDefault();
            var naam = $form.find('#newsletterName').val();
            var email = $form.find('#newsletterEmail').val();

            /* if (!HELPER.validateEmail(email)) {
                 $(this).find('.notvalid').slideDown();
                 return;
             }
             ;*/

            var formdata = {
                email: email,
                naam: naam
            };
            $form.find('.btn').attr('disabled', 'disabled');

            var btn = $(this).find('.btn');
            btn.prop("disabled", true);
            var loader = $(this).find('.loader-container');
            loader.show();
            $.ajax({
                type: "POST",
                url: ISTEC.siteurl + '/wp-content/themes/istec-international/tools/newslettersubscribe.php',
                data: formdata,
                timeout: 5000,
                success: function (data, textStatus, xhr) {
                    try {
                        var json = $.parseJSON(data);
                        var msgcolor = (json.status >= 600) ? 'red' : '';
                        btn.prop("disabled", false);
                        $('.mailchimpholder').html(json.text);

                    } catch (e) {
                        var msgcolor = 'red';
                        $('.mailchimpholder').html("Het lukt helaas niet om je in te schrijven. Neem contact op met info@istec.nl.");
                        $('.mailchimpholder').removeClass('hide');
                        $('.mailchimpholder').slideDown();
                        // $('.mailchimpholder').css('color', msgcolor);
                        loader.hide();
                    }
                    $('.mailchimpholder').removeClass('hide');
                    $('.mailchimpholder').slideDown();
                    // $('.mailchimpholder').css('color', msgcolor);
                    loader.hide();

                    if (json.status < 600) {
                        $(".nieuwsbrieflink").trigger("reset");
                    }
                },
                error: function (data) {
                    var msgcolor = 'red';
                    $('.mailchimpholder').html("Het lukt helaas niet om je in te schrijven. Neem contact op met info@istec.nl.");
                    $('.mailchimpholder').removeClass('hide');
                    $('.mailchimpholder').slideDown();
                    // $('.mailchimpholder').css('color', msgcolor);
                    loader.hide();
                }
            });

        });

    };


    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $('.newsletter_subscribe').newsletterSubscribe();
                /*
                $('.add-download-to-list').on('click', function (e) {
                    var today = new Date();
                    var donwload_id = $(this).data('download-id');
                    var downloads = $.cookie("downloads");
                    var downloadsArray = [downloads];
                    downloadsArray.push(donwload_id);
                    $.cookie("downloads", downloadsArray, { path: '/'});
                    $(".downloadcount").html(downloadsArray.length);

                });*/

                var mobileMenu = $('#fnav-menu');

                mobileMenu.find('li:has(ul)').prepend('<span class="menu-item-caret">▼</span>');
                mobileMenu.find('.menu-item-caret').click(function (e) {
                    $(this).parent('li.menu-item').find('ul').slideToggle(300);
                    return false;
                });

                $('.checkbox').click(function () {
                    var checkbox = $(this).find("input");

                    if (checkbox.is(':checked')) {
                        $(this).addClass("checked");
                    }
                    else {
                        $(this).removeClass("checked");
                    }
                });



                $(".mobile-navigation").on("click", function () {
                    $("#menu-hoofdmenu").toggleClass("mobile-open");
                });
                $(".menu-item-has-children").on("click", function (e) {
                    //e.preventDefault();
                    if ($(this).find('.sub-menu').is(":visible")) {
                        //     console.log($(this).find('.sub-menu').is(":visible"));
                        $(this).removeClass('child-open');
                        //   window.location = $(this).find("a").attr('href');
                    } else {

                        $(this).addClass('child-open');
                    }
                });

                //cookie-container
                if (!!$.cookie('allowcookieforsite')) {

                } else {

                    $("#cookiePopup").removeClass('hidden');//prop('checked', mycookie);
                    $("#allowcookie").on('click', function (e) {
                        e.preventDefault();
                        $.cookie('allowcookieforsite', true, {expires: 305, path: '/'});
                        $("#cookiePopup").addClass('hidden');
                    })
                }


                $('.our-work-carousel').owlCarousel({
                  nav: true,
                  loop: true,
                  dots: false,
                  lazyLoad: true,
                  navClass: ['btn btn-default prev', 'btn btn-default next'],
                  navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
                  responsiveBaseElement: 'body',
                  responsiveClass: true,
                  responsive: {
                    0: {
                      stagePadding: 0,
                      padding: 0,
                      items: 1
                    },
                    768: {
                      stagePadding: 100,
                      padding: 150,
                      items: 2
                    },
                    992: {
                      stagePadding: 100,
                      padding: 150,
                      items: 2
                    },
                    1200: {
                      stagePadding: 100,
                      padding: 150,
                      items: 3
                    }
                  }
                });

                $('.multi-item-carousel').carousel({
                    interval: 3000
                });


                $('.multi-item-carousel .item').each(function () {
                    var next = $(this).next();
                    if (!next.length) {
                        next = $(this).siblings(':first');
                    }
                    next.children(':first-child').clone().appendTo($(this));

                    if (next.next().length > 0) {
                        next.next().children(':first-child').clone().appendTo($(this));
                    } else {
                        $(this).siblings(':first').children(':first-child').clone().appendTo($(this));
                    }
                });

                if ($('#attentionPopup').length > 0) {
                    HELPER.popuphandler();
                }


                if ($('body.page-template-template-specialist-contact').length > 0) {

                    $('.wpcf7-submit').click(function () {
                        $('.wpcf7-submit').addClass('disabled');
                    });

                    var wpcf7Elm = document.querySelector('.wpcf7');

                    // wpcf7Elm.addEventListener('wpcf7mailsent', function (event) {
                    //     $('.wpcf7-submit').attr('disabled', true).addClass('disabled');
                    // }, false);
                    //
                    // wpcf7Elm.addEventListener('wpcf7invalid', function (event) {
                    //     $('.wpcf7-submit').attr('disabled', false).removeClass('disabled');
                    // }, false);
                    // wpcf7Elm.addEventListener('wpcf7spam', function (event) {
                    //     $('.wpcf7-submit').attr('disabled', false).removeClass('disabled');
                    // }, false);
                    // wpcf7Elm.addEventListener('wpcf7mailfailed', function (event) {
                    //     $('.wpcf7-submit').attr('disabled', false).removeClass('disabled');
                    // }, false);

                    wpcf7Elm.addEventListener('wpcf7mailsent', function (event) {
                        $('.wpcf7-submit').attr('disabled', true).addClass('disabled');
                    }, false);
                }
                $('.vid_trigger').click( function(e){
                    e.preventDefault();
                    var URL = $(this).attr('href');
                    if ($(window).width() < 768) {
                        var htm = '<iframe width="100%" src="//www.youtube.com/embed/' + URL + '?rel=0&autoplay=1" frameborder="0" allowfullscreen ></iframe>';
                        $(this).parent().parent().html(htm);
                    }else{
                        $(this).find('.widget-video-play').click();
                    }

                    return false;
                });


                var getUrlParameter = function getUrlParameter(sParam) {
                    var sPageURL = window.location.search.substring(1),
                        sURLVariables = sPageURL.split('&'),
                        sParameterName,
                        i;

                    for (i = 0; i < sURLVariables.length; i++) {
                        sParameterName = sURLVariables[i].split('=');

                        if (sParameterName[0] === sParam) {
                            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                        }
                    }
                };

                var searchProducten = getUrlParameter('search-producten');
                var searchKennis = getUrlParameter('search-kennisartikelen');
                var searchDownload = getUrlParameter('search-download');
                var searchSeminars = getUrlParameter('search-seminars');

                if(searchProducten === 'on') {
                    $('#searchtabs a[href="#search-producten"]').tab('show');
                }
                else if(searchKennis === 'on') {
                    $('#searchtabs a[href="#search-kennisartikelen"]').tab('show');
                }
                else if(searchDownload === 'on') {
                    $('#searchtabs a[href="#search-download"]').tab('show');
                }
                else if(searchSeminars === 'on') {
                    $('#searchtabs a[href="#search-seminars"]').tab('show');
                }


                $(".modal").on('hidden.bs.modal', function (e) {
                    $(".modal iframe").attr("src", $(".modal iframe").attr("src"));
                });


                if($('#productSubmenu a').length) {
                    // console.log('trest');
                    $(document).on('click', '#productSubmenu a[href^="#"]', function(e) {
                        console.log('click12');
                        var id = $(this).attr('href');

                        var $id = $(id);
                        if ($id.length === 0) {
                            return;
                        }

                        e.preventDefault();
                        var pos = $id.offset().top;

                        $('body, html').animate({scrollTop: pos});
                    });
                }

                // if($('.tablist-carousel a').length) {
                //
                //     $('.tablist-carousel a').on('click', function (e) {
                //         e.preventDefault();
                //         console.log($(this).attr('href'));
                //         $($(this).attr('href')).tab('show');
                //     })
                // }
            },
            finalize: function () {
                HELPER.bookazineHandle();
                // JavaScript to be fired on all pages, after page specific JS is fired

                $(window).load(function() {
                    carouselReadMoreHeight();
                });

                $(window).resize(function () {
                    carouselReadMoreHeight();
                });

                function carouselReadMoreHeight() {

                    var carouselMaxHeight = 0;

                    $('.carousel-item .carousel-item-inner').each(function () {
                        var thisH = $(this).height();
                        if (thisH > carouselMaxHeight) {
                            carouselMaxHeight = thisH;
                        }
                    });

                    // alert( carouselMaxHeight );
                    $('.carousel-item-see-more .carousel-item-inner').height(carouselMaxHeight);
                }
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                // Instantiate the Bootstrap carousel
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
